import React, { useState, useEffect } from "react";
import EditItem from "./EditItem";
import ProgressSteps from "./ProgressSteps";
import {
  COMPLETED,
  IMAGE_GENERATION,
  LIST_FOR_SALE,
  NFT_MINT,
  PENDING,
  PROGRESS_STATUS,
} from "../config/constants";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const TodoItem = ({
  todo,
  updatedTodos,
  updateTodo,
  handleOpenDeleteConfirmDialog,
  handlePause,
  isRunning,
}) => {
  // const [isPaused, setIsPaused] = useState(todo.isPaused);
  const [status, setStatus] = useState(todo.status);
  const [isEditing, setIsEditing] = useState(false);
  const [images, setImages] = useState(todo.images);
  const [progress, setProgress] = useState(0);
  const [activeStep, setActiveStep] = useState(
    PROGRESS_STATUS.indexOf(todo.status)
  );
  const [isFailure, setIsFailure] = useState(todo.isFailure);
  const [bgColor, setBgColor] = useState("");

  const [contractAddress, setContractAddress] = useState(todo.contractAddress);
  const isPaused = todo.isPaused;
  // console.log("TODO prompt", todo.prompt)
  // console.log("TODO status", todo.isPaused)
  // console.log("real status", isPaused)
  useEffect(() => {
    if (
      todo.status !== "Completed" &&
      updatedTodos?.isFetched &&
      updatedTodos?.data?.data?.data
    ) {
      const currentItem = updatedTodos.data.data.data.filter(
        (e) => e._id === todo._id
      );
      if (currentItem?.length > 0) {
        setActiveStep(PROGRESS_STATUS.indexOf(currentItem[0].status));
        setIsFailure(currentItem[0].isFailure);
        setContractAddress(currentItem[0].contractAddress);
        setStatus(currentItem[0].status);
        if (currentItem[0].status === IMAGE_GENERATION) {
          setProgress(
            (currentItem[0].imgGeneratedCount / currentItem[0].nftCount) * 100
          );
          setImages(currentItem[0].images);
        } else if (currentItem[0].status === NFT_MINT) {
          setProgress(
            (currentItem[0].mintCount / currentItem[0].nftCount) * 100
          );
        } else if (currentItem[0].status === LIST_FOR_SALE) {
          setProgress(
            (currentItem[0].listCount / currentItem[0].nftCount) * 100
          );
        }
      }
    }
  }, [
    todo.status,
    updatedTodos?.isFetched,
    todo._id,
    updatedTodos?.data?.data?.data,
  ]);

  useEffect(() => {
    let getBgColor = "bg-white";
    if (status === COMPLETED) getBgColor = "bg-green-100";
    if (isPaused) getBgColor = "bg-slate-300";
    setBgColor(getBgColor);
  }, [status, isPaused]);

  const handleUpdateTodo = (updatedTodo) => {
    updateTodo(updatedTodo);
    setIsEditing(false);
  };
  const clickCancel = () => {
    setIsEditing(false);
  };
  const handleSetPause = (id, value) => {
    handlePause(id, value);
  };
  // Set dynamic background class based on status
  const getBackgroundClass = () => {};

  return (
    <div className={`border p-4 rounded-lg shadow-sm ${bgColor}`}>
      <div className="flex justify-between items-center mb-2">
        {isEditing ? (
          <EditItem
            todo={todo}
            updateTodo={handleUpdateTodo}
            clickCancel={clickCancel}
          />
        ) : (
          <div className="flex-1">
            <div className="flex space-x-2">
              <p className="font-bold text-lg">Prompt: {todo.prompt}</p>
              {isPaused && (
                <span className="px-2 py-1 rounded text-white text-sm bg-slate-500">
                  Paused
                </span>
              )}
              {contractAddress ? (
                <p className="text-sm flex">
                  <a
                    href={`https://opensea.io/assets/matic/${contractAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <OpenInNewIcon style={{ marginRight: "4px" }} />
                    OpenSea
                  </a>
                </p>
              ) : (
                <div></div>
              )}
            </div>
            <p className="text-sm text-gray-600">
              Name: {todo.nftName} | Symbol: {todo.nftSymbol} | Width:{" "}
              {todo.width} | Height: {todo.height} | Price: {todo.price} | Type:{" "}
              {todo.nftCount} NFTs
            </p>
            <div className="flex space-x-4 mt-2">
              <button
                onClick={() => setIsEditing(true)}
                className="text-blue-500 hover:text-blue-700"
                hidden={todo.status !== PENDING}
              >
                Edit
              </button>
              <button
                onClick={() => handleOpenDeleteConfirmDialog(todo)}
                className="text-red-500 hover:text-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        )}
        <div>
          <button
            onClick={() => handleSetPause(todo._id, !isPaused)}
            className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700"
            hidden={status === COMPLETED}
          >
            {isPaused ? "Continue" : "Pause"}
          </button>
        </div>
      </div>
      <ProgressSteps
        activeStep={activeStep}
        progress={progress}
        isFailure={isFailure}
        isPaused={isPaused}
        isRunning={isRunning}
      />
      {/* Progress Bar and Images Section */}
      <div className="mt-4">
        {/* Image Preview Section */}
        <div className="flex overflow-x-auto space-x-2 p-2 border-t">
          {images.length > 0 ? (
            images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Generated ${index}`}
                className="w-24 h-24 object-cover"
              />
            ))
          ) : (
            <p className="text-gray-500">No images generated yet</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TodoItem;
