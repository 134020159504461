import React, { useState, useEffect } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getSeconds()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  } else {
    return "";
  }
};

const LoggingPage = () => {
  const [loggingData, setLoggingData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    getLoggingData(page);
  }, [page]);

  const getLoggingData = async (currentPage) => {
    try {
      console.log("Fetching logging data...");
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/nft/logging",
        { params: { page: currentPage, limit: 20 } }
      );
      const { logs: newLogs } = response.data;

      if (newLogs.length === 0) {
        setHasMore(false); // No more data to load
      } else {
        setLoggingData((prevLogs) =>
          [...prevLogs, ...newLogs].sort(
            (a, b) => new Date(b.event_time) - new Date(a.event_time)
          )
        );
      }
    } catch (error) {
      console.error("Error fetching logging data:", error);
    }
  };

  const fetchMoreLogs = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="h-full p-6 overflow-x-auto" id="scrollableDiv">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold mb-4">Logging Table</h1>
      </div>

      <div className="border rounded-lg shadow">
        <table className="min-w-full border border-gray-300 bg-white text-sm ">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Event time
              </th>
              <th className="px-4 py-2 border-b text-left font-medium text-gray-600">
                Event description
              </th>
            </tr>
          </thead>
          <tbody>
            <InfiniteScroll
              dataLength={loggingData.length} // Total number of items fetched so far
              next={fetchMoreLogs} // Function to fetch more data
              hasMore={hasMore} // Whether more data is available
              loader={<p>Loading...</p>} // Displayed while fetching
              scrollableTarget="scrollableDiv"
            >
              {loggingData.map((log, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="px-4 py-2 border-b">
                    {formatDate(log.event_time)}
                  </td>
                  <td className="px-4 py-2 border-b">
                    {log.event_description}
                  </td>
                </tr>
              ))}
            </InfiniteScroll>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoggingPage;
